// src/functions/JointProbabilityDistribution.js
import React from 'react';
import './JointProbabilityDistribution.css'; // Use for colors and styling

const dataSource = [
  { key: '1', X: 'X=1', Y1: 0.1, Y2: 0.05, Y3: 0.15 },
  { key: '2', X: 'X=2', Y1: 0.05, Y2: 0.2, Y3: 0.1 },
  { key: '3', X: 'X=3', Y1: 0.1, Y2: 0.1, Y3: 0.15 },
];

// Visualization Component
const JointProbabilityVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Joint Probability Distribution</h3>
      <table className="probability-table">
        <thead>
          <tr>
            <th>X<br />Y</th> {/* Added line break for better alignment */}
            <th>Y=1</th>
            <th>Y=2</th>
            <th>Y=3</th>
          </tr>
        </thead>
        <tbody>
          {dataSource.map((row) => (
            <tr key={row.key}>
              <td>{row.X}</td>
              <td>{row.Y1}</td>
              <td>{row.Y2}</td>
              <td>{row.Y3}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="visualization-description">
        The table represents the <strong>joint probability distribution</strong> of discrete random variables X and Y. Each cell shows P(X = x, Y = y).
      </p>
    </div>
  );
};

// Main Joint Probability Distribution Function Object
const JointProbabilityDistribution = {
  name: 'Joint Probability Distribution (P(X, Y))',
  description:
    'The joint probability distribution defines the probability of two discrete random variables occurring simultaneously. It provides a complete description of their probabilistic relationship.',
  goodFor: [
    'Analyzing relationships between variables.',
    'Calculating marginal and conditional probabilities.',
  ],
  notGoodFor: [
    'Continuous variables without discretization.',
    'High-dimensional data (becomes complex).',
  ],
  visualization: JointProbabilityVisualization,
  input: 'Discrete random variables X and Y with joint probabilities.',
  output: 'A function assigning probabilities to each pair (x, y).',
  formula: 'P(X = x, Y = y)',
};

export default JointProbabilityDistribution;

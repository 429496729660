// src/functions/ChebyshevInequality.js
import React from 'react';
import './ChebyshevInequality.css'; // Use for colors and styling

const ChebyshevVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Chebyshev's Inequality</h3>
      <p className="visualization-description">
        Chebyshev's Inequality states that for any real number k {'>'} 0:
        <br />
        <strong>P(|X - μ| ≥ kσ) ≤ 1 / k²</strong>
        <br />
        It provides a bound on the probability that a random variable deviates from its mean.
      </p>
    </div>
  );
};

// Main Chebyshev's Inequality Function Object
const ChebyshevInequality = {
  name: "Chebyshev's Inequality",
  description:
    "Chebyshev's Inequality gives an upper bound on the probability that a random variable deviates from its mean by more than a certain multiple of the standard deviation.",
  goodFor: [
    'Analyzing data without knowing the distribution.',
    'Providing worst-case bounds.',
  ],
  notGoodFor: [
    'Tight bounds on probabilities.',
    'Data known to be normally distributed.',
  ],
  visualization: ChebyshevVisualization,
  input: 'Random variable X with mean μ and standard deviation σ, and value k > 0.',
  output: 'An upper bound on P(|X - μ| ≥ kσ).',
  formula: 'P(|X - μ| ≥ kσ) ≤ \\frac{1}{k^2}',
};

export default ChebyshevInequality;

// src/functions/MarginalDistribution.js
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import './MarginalDistribution.css'; // Use for colors and styling

const jointData = [
  { x: 1, y: 1, p: 0.1 },
  { x: 1, y: 2, p: 0.05 },
  { x: 1, y: 3, p: 0.15 },
  { x: 2, y: 1, p: 0.05 },
  { x: 2, y: 2, p: 0.2 },
  { x: 2, y: 3, p: 0.1 },
  { x: 3, y: 1, p: 0.1 },
  { x: 3, y: 2, p: 0.1 },
  { x: 3, y: 3, p: 0.15 },
];

// Calculate Marginal Distribution
const calculateMarginalX = (data) => {
  const marginalX = {};
  data.forEach((item) => {
    marginalX[item.x] = (marginalX[item.x] || 0) + item.p;
  });
  return Object.entries(marginalX).map(([x, p]) => ({ x, p }));
};

// Visualization Component
const MarginalDistributionVisualization = () => {
  const marginalXData = calculateMarginalX(jointData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Marginal Distribution of X</h3>
      <BarChart
        width={400}
        height={250}
        data={marginalXData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid />
        <XAxis dataKey="x" label={{ value: 'X', position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value: 'P(X)', angle: -90, position: 'insideLeft' }} />
        <Tooltip formatter={(value) => `P(X=${value})`} />
        <Bar dataKey="p" fill="#8e44ad">
          <LabelList dataKey="p" position="top" formatter={(value) => value.toFixed(2)} />
        </Bar>
      </BarChart>
      <p className="visualization-description">
        This chart represents the <strong>marginal distribution</strong> of X, obtained by summing the joint probabilities over all values of Y.
      </p>
    </div>
  );
};

// Main Marginal Distribution Function Object
const MarginalDistribution = {
  name: 'Marginal Distribution',
  description:
    'The marginal distribution of a variable is the probability distribution of the variables contained in the subset.',
  goodFor: [
    'Understanding individual variable distributions.',
    'Calculating expectations of a single variable.',
  ],
  notGoodFor: [
    'Capturing joint dependencies.',
    'Analyzing interactions between variables.',
  ],
  visualization: MarginalDistributionVisualization,
  input: 'Joint probability distribution of variables X and Y.',
  output: 'Probability distribution of X (or Y) individually.',
  formula: 'P_X(x) = ∑_y P(X = x, Y = y)',
};

export default MarginalDistribution;

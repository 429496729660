// src/functions/TotalProbability.js
import React from 'react';
import './TotalProbability.css'; // Use for colors and styling

const TotalProbabilityVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Total Probability Theorem</h3>
      <p className="visualization-description">
        The total probability of event A is calculated using the probabilities of mutually exclusive and exhaustive events B₁, B₂, ..., Bₙ:
        <br />
        <strong>P(A) = ∑ P(A|Bᵢ) P(Bᵢ)</strong>
      </p>
    </div>
  );
};

// Main Total Probability Function Object
const TotalProbability = {
  name: 'Total Probability',
  description:
    'The total probability theorem relates the probability of an event to conditional probabilities on a partition of the sample space.',
  goodFor: [
    'Calculating probabilities in complex scenarios.',
    'Breaking down problems into simpler parts.',
  ],
  notGoodFor: [
    'Non-exhaustive or overlapping events.',
    'Independent events (simpler methods exist).',
  ],
  visualization: TotalProbabilityVisualization,
  input: 'Conditional probabilities P(A|Bᵢ) and probabilities P(Bᵢ).',
  output: 'The total probability P(A).',
  formula: 'P(A) = ∑_{i} P(A|B_i) P(B_i)',
};

export default TotalProbability;

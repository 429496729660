import React, { useState, useEffect } from 'react';
import {
    ChevronLeft,
    ChevronRight,
    Maximize,
    Minimize,
    Brain,
    Puzzle,
    Zap,
    ArrowRight,
    HelpCircle,
    FileJson,
    Grid,
    BarChart,
    BarChart2
  } from 'lucide-react';
import './ARCPresentation.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ARCPresentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (content, event) => {
    const rect = event.target.getBoundingClientRect();
    setTooltipContent(content);
    setTooltipPosition({
      x: rect.left + window.scrollX,
      y: rect.bottom + window.scrollY
    });
  };

  const handleMouseLeave = () => {
    setTooltipContent(null);
  };

  const caeTooltip = (
    <Tooltip id="cae-tooltip">
      <ul>
        <li>Specializes in spatial feature extraction</li>
        <li>Preserves local structure in grid-based data</li>
        <li>Ideal for ARC's 2D grid inputs</li>
      </ul>
    </Tooltip>
  );

  const vaeTooltip = (
    <Tooltip id="vae-tooltip">
      <ul>
        <li>Learns probabilistic latent representations</li>
        <li>Captures underlying data distribution</li>
        <li>Helps with generalization to novel patterns</li>
      </ul>
    </Tooltip>
  );

  const dbnTooltip = (
    <Tooltip id="dbn-tooltip">
      <ul>
        <li>Hierarchical feature learning</li>
        <li>Good at discovering high-level abstractions</li>
        <li>Useful for complex pattern recognition in ARC tasks</li>
      </ul>
    </Tooltip>
  );

  const slides = [
    {
      title: "Solving the ARC Challenge",
      content: (
        <div className="slide-content">
          <div className="left-column">
            <p className="intro-text">
              The Abstraction and Reasoning Corpus (ARC) is a unique benchmark designed to measure AI skill acquisition and track progress towards human-level AI.
            </p>
            <div className="key-points">
              <div className="key-point">
                <Brain size={80} />
                <span>Human-like reasoning</span>
              </div>
              <div className="key-point">
                <Puzzle size={80} />
                <span>Complex pattern recognition</span>
              </div>
              <div className="key-point">
                <Zap size={80} />
                <span>Adaptive problem-solving</span>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="arc-example-wrapper">
              <h3>ARC Puzzle Example</h3>
              <br></br>
              <div className="arc-example">
                <div className="arc-grid">
                  {[...Array(100)].map((_, i) => (
                    <div
                      key={i}
                      className={`arc-cell ${i % 13 === 0 || i % 17 === 0 ? 'green' : ''} ${
                        i % 29 === 0 ? 'yellow' : ''
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
              <br></br>
              <p className="arc-caption">
                AI must discern patterns and complete the puzzle.
              </p>
            </div>
          </div>
        </div>
      )
    },
    {
      title: "Understanding the ARC Challenge",
      content: (
        <div className="slide understanding-arc">
          <div className="slide-content">
            <div className="explanation">
              <p className="intro-text">
              The first ARC-AGI competition was started 2020 by François Chollets. It has become a huge competition with cash prices as incentives to speed up the "road to AGI".
              </p>
            </div>
            <div className="key-concept">
              <h3>Key Concept</h3>
              <p>
                Identify the transformation rule (or rules) that turns input to output, then apply it to the test output.
              </p>
            </div>
            <div className="visual-metaphor">
              <div className="grid-pattern"></div>
              <div className="connection-lines"></div>
            </div>
            <div className="examples-grid">
              <div className="example">
                <h3>Example 1: Color Change</h3>
                <div className="arc-example-set">
                  <div className="arc-pair">
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 7 === 0 ? 'green' : ''}`}></div>
                      ))}
                    </div>
                    <ArrowRight size={24} />
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 7 === 0 ? 'yellow' : ''}`}></div>
                      ))}
                    </div>
                  </div>
                  <p className="rule">Rule: Green cells become yellow</p>
                </div>
              </div>
              <div className="example">
                <h3>Example 2: Pattern Shift</h3>
                <div className="arc-example-set">
                  <div className="arc-pair">
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 5 === 0 ? 'blue' : ''}`}></div>
                      ))}
                    </div>
                    <ArrowRight size={24} />
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div
                          key={i}
                          className={`arc-cell ${(i + 1) % 5 === 0 ? 'blue' : ''}`}
                        ></div>
                      ))}
                    </div>
                  </div>
                  <p className="rule">Rule: Blue pattern shifts right by 1</p>
                </div>
              </div>
              <div className="example">
                <h3>Example 3: Inversion</h3>
                <div className="arc-example-set">
                  <div className="arc-pair">
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 3 === 0 ? 'red' : ''}`}></div>
                      ))}
                    </div>
                    <ArrowRight size={24} />
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 3 !== 0 ? 'red' : ''}`}></div>
                      ))}
                    </div>
                  </div>
                  <p className="rule">Rule: Invert red and non-red cells</p>
                </div>
              </div>
              <div className="example challenge">
                <h3>Challenge</h3>
                <div className="arc-example-set">
                  <div className="arc-pair">
                    <div className="arc-grid small">
                      {[...Array(25)].map((_, i) => (
                        <div key={i} className={`arc-cell ${i % 4 === 0 ? 'purple' : ''}`}></div>
                      ))}
                    </div>
                    <ArrowRight size={24} />
                    <div className="arc-grid small question-mark">
                      <HelpCircle size={40} />
                    </div>
                  </div>
                  <p className="rule">Will my future model be able to predict the output?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
    title: "ARC Dataset",
    content: (
        <div className="dataset-overview">
      {/* New: Dataset Structure section */}
      <div className="dataset-info">
        <h3></h3>
        <div className="info-grid">
          <div className="info-item">
            <FileJson size={80} />
            <span STYLE="font-size:18.0pt">JSON Format</span>
          </div>
          <div className="info-item">
            <Grid size={80} />
            <span STYLE="font-size:18.0pt">1x1 to 30x30 Grids</span>
          </div>
          <div className="info-item">
            <BarChart2 size={80} />
            <span STYLE="font-size:18.0pt">800 Total Tasks</span>
          </div>
        </div>
      </div>
      
      {/* New: Task Composition section */}
      <div className="task-structure">
        <h3>Task Composition</h3>
        <div className="task-diagram">
          <div className="task-section">
            <h4>Training</h4>
            {[1, 2, 3].map((_, index) => (
              <div key={index} className="task-pair">
                <span>Input</span>
                <ArrowRight size={16} />
                <span>Output</span>
              </div>
            ))}
          </div>
          <div className="task-section">
            <h4>Testing</h4>
            <div className="task-pair">
              <span>Input</span>
              <ArrowRight size={16} />
              <span>?</span>
            </div>
          </div>
        </div>
      </div>
      
      {/* New: Key Points section */}
      <div className="dataset-notes">
        <h3>Key Points</h3>
        <ul>
          <li>Dynamic grid dims (1x1 to 30x30)</li>
          <li>A cell can be one of 10 Colors (0-9)</li>
          <li>A task have 2-5 grid pairs</li>
          <li>Final test with hidden validation set</li>
        </ul>
      </div>
    </div>
    )
    },
    {
        title: "Project Objective and Approach",
        content: (
          <div className="project-approach">
            <div className="approach-section">
              <h3>The Approach</h3>
              <ul className="key-components">
                <li>
                  Enhance CNN prediction using the output from the 3 unsupervised algorithms
                </li>
                <li>
                  Implement an attention mechanism to prioritize the most relevant data
                </li>
                <li>Make it end-to-end trainable - able to train all networks at the same time</li>
              </ul>
            </div>
            <div className="approach-section">
              <h3>Primary Unsupervised Networks</h3>
              <ul className="key-components">
                <li
                  onMouseEnter={(e) => handleMouseEnter(
                    <ul>
                      <li>Specializes in spatial feature extraction</li>
                      <li>Preserves local structure in grid-based data</li>
                      <li>Ideal for ARC's 2D grid inputs</li>
                    </ul>,
                    e
                  )}
                  onMouseLeave={handleMouseLeave}
                >
                  Convolutional Autoencoder (CAE): Spatial feature extraction, ideal for ARC's 2D grids
                </li>
                <li
                  onMouseEnter={(e) => handleMouseEnter(
                    <ul>
                      <li>Learns probabilistic latent representations</li>
                      <li>Captures underlying data distribution</li>
                      <li>Helps with generalization to novel patterns</li>
                    </ul>,
                    e
                  )}
                  onMouseLeave={handleMouseLeave}
                >
                  Variational Autoencoder (VAE): Learns probabilistic representations, aids generalization
                </li>
                <li
                  onMouseEnter={(e) => handleMouseEnter(
                    <ul>
                      <li>Hierarchical feature learning</li>
                      <li>Good at discovering high-level abstractions</li>
                      <li>Useful for complex pattern recognition in ARC tasks</li>
                    </ul>,
                    e
                  )}
                  onMouseLeave={handleMouseLeave}
                >
                  Deep Belief Network (DBN): Discovers high-level abstractions for complex patterns
                </li>
              </ul>
            </div>
            <div className="approach-section">
              <h3>Other Components</h3>
              <ul className="key-components">
                <li>Attention mechanism: Standard Scaled Dot-Product Attention</li>
                <li>CNN: For processing grid-based inputs and making predictions</li>
              </ul>
            </div>
          </div>
        )
      },
    {
      title: "Proposed Architecture",
      content: (
        <div className="architecture-diagram">
          <svg width="100%" height="100%" viewBox="-20 0 850 400">
            {/* Define markers for arrowheads */}
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="10"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="var(--text-color)" />
              </marker>
            </defs>

            {/* Input Node */}
            <rect
              x="10"
              y="170"
              width="100"
              height="80"
              rx="5"
              ry="5"
              className="arch-node input"
            />
            <text x="60" y="200" textAnchor="middle" className="node-title">
              Input
            </text>
            <g transform="translate(20, 210)">
              <rect width="80" height="30" rx="2" ry="2" fill="#000" />
              {[...Array(25)].map((_, i) => (
                <rect
                  key={i}
                  x={(i % 5) * 16}
                  y={Math.floor(i / 5) * 6}
                  width="14"
                  height="4"
                  fill={i % 7 === 0 ? '#2ecc71' : '#000'}
                />
              ))}
            </g>

            {/* Unsupervised Networks */}
            <rect
              x="160"
              y="100"
              width="120"
              height="60"
              rx="5"
              ry="5"
              className="arch-node un-nn"
            />
            <text x="220" y="135" textAnchor="middle" className="node-title">
              CAE
            </text>

            <rect
              x="160"
              y="180"
              width="120"
              height="60"
              rx="5"
              ry="5"
              className="arch-node un-nn"
            />
            <text x="220" y="215" textAnchor="middle" className="node-title">
              VAE
            </text>

            <rect
              x="160"
              y="260"
              width="120"
              height="60"
              rx="5"
              ry="5"
              className="arch-node un-nn"
            />
            <text x="220" y="295" textAnchor="middle" className="node-title">
              DBN
            </text>

            {/* Attention Mechanism */}
            <rect
              x="340"
              y="180"
              width="120"
              height="60"
              rx="5"
              ry="5"
              className="arch-node attention"
            />
            <text x="400" y="215" textAnchor="middle" className="node-title">
              Attention
            </text>

            {/* CNN */}
            <rect
              x="520"
              y="180"
              width="120"
              height="60"
              rx="5"
              ry="5"
              className="arch-node cnn"
            />
            <text x="580" y="215" textAnchor="middle" className="node-title">
              CNN
            </text>

            {/* Output Node */}
            <rect
              x="700"
              y="170"
              width="100"
              height="80"
              rx="5"
              ry="5"
              className="arch-node output"
            />
            <text x="750" y="200" textAnchor="middle" className="node-title">
              Output
            </text>
            <g transform="translate(710, 210)">
              <rect width="80" height="30" rx="2" ry="2" fill="#000" />
              {[...Array(25)].map((_, i) => (
                <rect
                  key={i}
                  x={(i % 5) * 16}
                  y={Math.floor(i / 5) * 6}
                  width="14"
                  height="4"
                  fill={i % 7 === 0 ? '#f1c40f' : '#000'}
                />
              ))}
            </g>

            {/* Arrows */}
            <path d="M110 210 L160 130" className="arch-arrow" />
            <path d="M110 210 L160 210" className="arch-arrow" />
            <path d="M110 210 L160 290" className="arch-arrow" />

            <path d="M280 130 L340 210" className="arch-arrow" />
            <path d="M280 210 L340 210" className="arch-arrow" />
            <path d="M280 290 L340 210" className="arch-arrow" />

            <path d="M460 210 L520 210" className="arch-arrow" />
            <path d="M640 210 L700 210" className="arch-arrow" />
          </svg>
        </div>
      )
    },
    {
      title: "Expected Outcomes and Future Directions",
      content: (
        <div className="outcomes-future">
          <div className="outcomes-section">
            <h3>Expected Outcomes</h3>
            <ul className="outcomes-list">
              <li>Improved performance on ARC puzzles</li>
              <li>AI capable of handling diverse problem types</li>
              <li>Problem-solving approach mimicking human cognition</li>
            </ul>
          </div>
          <div className="outcomes-section">
            <h3>Future Directions</h3>
            <ul className="outcomes-list">
              <li>Explore advanced unsupervised learning methods</li>
              <li>Develop AI's ability to learn from its attempts</li>
              <li>Optimize system for speed and efficiency</li>
            </ul>
          </div>
        </div>
      )
    },
    {
      title: "",
      content: (
        <div className="thank-you-content">
          <h2>Thank You!</h2>
          <p>Any questions?</p>
        </div>
      )
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const toggleFullscreen = () => {
    const presentation = document.querySelector('.arc-presentation');
    if (!isFullscreen) {
      if (presentation.requestFullscreen) {
        presentation.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextSlide();
      } else if (event.key === 'ArrowLeft') {
        prevSlide();
      } else if (event.key === 'Escape' && isFullscreen) {
        toggleFullscreen();
      }
    };

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  return (
    <div className={`arc-presentation ${isFullscreen ? 'fullscreen' : ''}`}>
      <div className="presentation-container">
        <div className="slide">
          <h1 className="slide-title">{slides[currentSlide].title}</h1>
          {slides[currentSlide].content}
        </div>
        <button
          className="nav-button prev"
          onClick={(e) => {
            e.stopPropagation();
            prevSlide();
          }}
        >
          <ChevronLeft size={24} />
        </button>
        <button
          className="nav-button next"
          onClick={(e) => {
            e.stopPropagation();
            nextSlide();
          }}
        >
          <ChevronRight size={24} />
        </button>
        <button
          className="nav-button fullscreen"
          onClick={(e) => {
            e.stopPropagation();
            toggleFullscreen();
          }}
        >
          {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
        </button>
        <div className="slide-indicator">
          {currentSlide + 1} / {slides.length}
        </div>
      </div>
      {tooltipContent && (
        <div
          className="custom-tooltip"
          style={{
            position: 'absolute',
            top: `${tooltipPosition.y}px`,
            left: `${tooltipPosition.x}px`,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default ARCPresentation;

import React, { useState, useMemo, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { Tab, Nav, Button } from 'react-bootstrap';
import { Info, Check, X } from 'lucide-react';
import './Mean.css';

const Mean = () => {
  // Initialize state for data values (using a real-world example: daily temperatures in Celsius)
  const [data, setData] = useState([
    { name: 'Mon', value: 20 },
    { name: 'Tue', value: 22 },
    { name: 'Wed', value: 21 },
    { name: 'Thu', value: 24 },
    { name: 'Fri', value: 23 },
  ]);

  // State for dynamic proof steps
  const [dynamicStep, setDynamicStep] = useState(0);

  // State for quiz
  const [quizStep, setQuizStep] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);

  // Calculations
  const n = data.length;
  const sum = data.reduce((acc, item) => acc + item.value, 0);
  const mean = parseFloat((sum / n).toFixed(2));

  // Color legend for variables
  const colorLegend = {
    x: '#4CAF50', // Green
    n: '#2196F3', // Blue
    sum: '#FFC107', // Amber
    mean: '#9C27B0', // Purple
  };

  // Dynamic proof steps
  const dynamicSteps = [
    {
      explanation: 'Define the dataset:',
      formula: `x = [${data.map((item) => item.value).join(', ')}]`,
      highlight: `\\color{${colorLegend.x}}{x = [${data.map((item) => item.value).join(', ')}]}`,
      variable: { name: 'x', value: `[${data.map((item) => item.value).join(', ')}]`, color: colorLegend.x },
      stepColor: colorLegend.x,
    },
    {
      explanation: 'Count the number of values:',
      formula: `n = ${n}`,
      highlight: `\\color{${colorLegend.n}}{n = ${n}}`,
      variable: { name: 'n', value: n, color: colorLegend.n },
      stepColor: colorLegend.n,
    },
    {
      explanation: 'Calculate the sum of all values:',
      formula: `\\sum x = ${data.map((item) => item.value).join(' + ')} = ${sum}`,
      highlight: `\\color{${colorLegend.sum}}{\\sum x = ${data.map((item) => item.value).join(' + ')} = ${sum}}`,
      variable: { name: 'Σx', value: sum, color: colorLegend.sum },
      stepColor: colorLegend.sum,
    },
    {
      explanation: 'Compute the mean (μ represents the population mean):',
      formula: `μ = \\frac{\\sum x}{n} = \\frac{${sum}}{${n}} = ${mean}`,
      highlight: `\\color{${colorLegend.mean}}{μ} = \\frac{\\color{${colorLegend.sum}}{${sum}}}{\\color{${colorLegend.n}}{${n}}} = \\color{${colorLegend.mean}}{${mean}}`,
      variable: { name: 'μ', value: mean, color: colorLegend.mean },
      stepColor: colorLegend.mean,
    },
  ];

  // Quiz questions
  const quizQuestions = [
    {
      question: 'What is the sum of the temperatures for the week?',
      correctAnswer: sum,
      explanation: `Adding all the temperatures: <span style="color:${colorLegend.x}">${data.map((item) => item.value).join(' + ')}</span> = <span style="color:${colorLegend.sum}">${sum}°C</span>`,
    },
    {
      question: 'What is the average (mean) temperature for the week?',
      correctAnswer: mean,
      explanation: `Dividing the sum by the number of days: <span style="color:${colorLegend.sum}">${sum}°C</span> / <span style="color:${colorLegend.n}">${n} days</span> = <span style="color:${colorLegend.mean}">${mean}°C</span>`,
    },
    {
      question: 'If Monday\'s temperature was 5°C higher, what would be the new mean?',
      correctAnswer: parseFloat(((sum + 5) / n).toFixed(2)),
      explanation: `New sum: <span style="color:${colorLegend.sum}">${sum + 5}°C</span>, New mean: (<span style="color:${colorLegend.sum}">${sum + 5}°C</span>) / <span style="color:${colorLegend.n}">${n} days</span> = <span style="color:${colorLegend.mean}">${parseFloat(((sum + 5) / n).toFixed(2))}°C</span>`,
    },
    {
      question: 'How many degrees above the mean is the highest temperature?',
      correctAnswer: Math.max(...data.map(item => item.value)) - mean,
      explanation: `Highest temperature: <span style="color:${colorLegend.x}">${Math.max(...data.map(item => item.value))}°C</span>, Mean: <span style="color:${colorLegend.mean}">${mean}°C</span>, Difference: <span style="color:${colorLegend.x}">${Math.max(...data.map(item => item.value)) - mean}°C</span>`,
    },
  ];

  // Function to add a new data point
  const addDataPoint = () => {
    const newValue = 20; // Default temperature value
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const newDayName = dayNames[data.length % 7]; // Ensure it cycles through day names
    setData([...data, { name: newDayName, value: newValue }]);
  };

  // Function to remove the last data point
  const removeDataPoint = () => {
    if (data.length > 1) {
      setData(data.slice(0, -1));
    }
  };

  // Function to update a data value
  const updateDataValue = (index, value) => {
    const newData = [...data];
    newData[index].value = parseFloat(value) || 0;
    setData(newData);
  };

  // Handle navigation in dynamic proof
  const nextDynamicStep = () => {
    if (dynamicStep < dynamicSteps.length - 1) {
      setDynamicStep(dynamicStep + 1);
    }
  };

  const prevDynamicStep = () => {
    if (dynamicStep > 0) {
      setDynamicStep(dynamicStep - 1);
    }
  };

  // Handle quiz answer checking
  const checkAnswer = () => {
    const correct = Math.abs(parseFloat(userAnswer) - quizQuestions[quizStep].correctAnswer) < 0.01;
    setFeedback(correct);
    setShowExplanation(true);
  };

  const nextQuizStep = () => {
    if (quizStep < quizQuestions.length - 1) {
      setQuizStep(quizStep + 1);
      setUserAnswer('');
      setFeedback(null);
      setShowExplanation(false);
    }
  };

  const prevQuizStep = () => {
    if (quizStep > 0) {
      setQuizStep(quizStep - 1);
      setUserAnswer('');
      setFeedback(null);
      setShowExplanation(false);
    }
  };

  const resetQuiz = () => {
    setQuizStep(0);
    setUserAnswer('');
    setFeedback(null);
    setShowExplanation(false);
  };

    // Function to group data by weeks (7 days per row)
  const groupByWeeks = (data) => {
    const weeks = [];
    for (let i = 0; i < data.length; i += 7) {
      weeks.push(data.slice(i, i + 7));
    }
    return weeks;
  };

  const WeeksChart = ({ data, mean }) => {
    const weeksData = groupByWeeks(data);

    return (
      <div className="weeks-chart-container">
        {weeksData.map((week, index) => (
          <div className="week-row" key={index} style={{ display: 'flex', alignItems: 'center' }}>
            {/* Week label */}
            <div className="week-label" style={{ marginRight: '10px', fontWeight: 'bold' }}>
              Week {index + 1}
            </div>
            {/* Responsive Container for the chart */}
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={week}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#4299e1" maxBarSize={50}>
                  <LabelList dataKey="value" position="top" />
                </Bar>
                <ReferenceLine
                  y={mean}
                  label={{
                    position: 'right',
                    value: `Mean: ${mean}°C`,
                    fill: '#e53e3e',
                    fontSize: 14,
                    align: 'left',
                  }}
                  stroke="#e53e3e"
                  strokeWidth={2}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    );
  };



  // Memoize chart component
  const memoizedChart = useMemo(
    () => (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 50, right: 100, left: 0, bottom: 20 }} // Adjust the right margin
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill="#4299e1" maxBarSize={50}>
            <LabelList dataKey="value" position="top" />
          </Bar>
          <ReferenceLine
            y={mean}
            label={{
              position: 'right', // Move to the right
              value: `Mean: ${mean}°C`,
              fill: '#e53e3e',
              fontSize: 14,
              align: 'left',
              offset: 20 // Provide some offset to push the text inward
            }}
            stroke="#e53e3e"
            strokeWidth={2}
          />
        </BarChart>
      </ResponsiveContainer>
    ),
    [data, mean]
  );

  // Memoize formula proof component
  const FormulaProof = React.memo(() => {
    const proofSteps = useMemo(
      () => [
        {
          text: "Start with the definition of the mean: The sum of all values divided by the number of values.",
          formula: "μ = \\frac{x_1 + x_2 + ... + x_n}{n}",
          highlight: `\\color{${colorLegend.mean}}{μ} = \\frac{\\color{${colorLegend.x}}x_1 + x_2 + ... + x_n}{\\color{${colorLegend.n}}n}`
        },
        {
          text: "Express this mathematically using summation:",
          formula: "μ = \\frac{\\sum_{i=1}^{n} x_i}{n}",
          highlight: `\\color{${colorLegend.mean}}{μ} = \\frac{\\color{${colorLegend.sum}}{\\sum_{i=1}^{n} x_i}}{\\color{${colorLegend.n}}{n}}`
        },
        {
          text: "Simplify by factoring the constant:",
          formula: "μ = \\frac{1}{n} \\cdot \\sum_{i=1}^{n} x_i",
          highlight: `\\color{${colorLegend.mean}}{μ} = \\color{${colorLegend.n}}{\\frac{1}{n}} \\cdot \\color{${colorLegend.sum}}{\\sum_{i=1}^{n} x_i}`
        },
        {
          text: "This is the final formula for the mean:",
          formula: "μ = \\frac{1}{n} \\sum_{i=1}^{n} x_i",
          highlight: `\\color{${colorLegend.mean}}{μ} = \\frac{\\color{${colorLegend.n}}{1}}{\\color{${colorLegend.n}}{n}} \\color{${colorLegend.sum}}{\\sum_{i=1}^{n} x_i}`
        }
      ],
      []
    );

    return (
      <div className="formula-proof">
        <h2 className="section-title">Mathematical Formula and Proof</h2>
        <div className="formula-proof__main-formula">
          <BlockMath
            math={`\\color{${colorLegend.mean}}{μ} = \\frac{\\color{${colorLegend.n}}{1}}{\\color{${colorLegend.n}}{n}} \\color{${colorLegend.sum}}{\\sum_{i=1}^{n} x_i}`}
          />
        </div>
        <div className="formula-proof__legend">
          <p>
            Where:
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.mean}}{μ}`} /> is the mean
            </span>
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.n}}{n}`} /> is the number of values
            </span>
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.x}}{x_i}`} /> represents each value in the dataset
            </span>
          </p>
        </div>
        <div className="formula-proof__proof">
          <h4 className="formula-proof__subtitle">Proof:</h4>
          <ol className="formula-proof__steps">
            {proofSteps.map((step, index) => (
              <li key={index} className="formula-proof__step">
                <div className="formula-proof__step-content">
                  <p>{step.text}</p>
                  <div className="highlighted-formula">
                    <BlockMath math={step.highlight} />
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
        <div className="formula-proof__conclusion">
          <Info size={18} />
          <p>
            This proof shows that our formula is equivalent to the basic definition of the mean, which is the sum of all values divided by the number of values.
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className="mean-container">
      <h1 className="function-title">Calculating the Mean</h1>
      <p className="function-description">
        Learn how to calculate the average (mean) through interactive visualizations and quizzes.
      </p>

      <div className="introduction">
        <h2>What is the Mean?</h2>
        <p>
          The mean, often called the average, is a measure of central tendency in statistics. It represents the typical or central value in a dataset. The mean is calculated by summing all values and dividing by the number of values.
        </p>
        <p>
          In this interactive tool, we'll explore the concept of mean using daily temperature data as an example.
        </p>
      </div>

      {/* Visualization */}
      <div className="visualization-container">
        {memoizedChart}
      </div>

      {/* Input Section */}
      <div className="input-section">
        <h3>Input Temperature Data (°C)</h3>
        <div className="input-container">
          {data.map((item, index) => (
            <div key={index} className="input-item">
              <label>{item.name}</label>
              <input
                type="number"
                value={item.value}
                onChange={(e) => updateDataValue(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="input-buttons">
          <button onClick={addDataPoint}>Add Day</button>
          <button onClick={removeDataPoint}>Remove Day</button>
        </div>
      </div>

      {/* Tabs for Dynamic Proof and Quiz */}
      <Tab.Container defaultActiveKey="dynamicProof">
        <Nav variant="pills" className="nav-pills">
          <Nav.Item>
            <Nav.Link eventKey="dynamicProof">Dynamic Proof</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="quiz">Quiz</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          {/* Dynamic Proof Tab */}
          <Tab.Pane eventKey="dynamicProof">
            <div className="dynamic-proof">
              <h3>Dynamic Proof</h3>
              <div className="step-journey">
                {dynamicSteps.map((step, index) => (
                  <div
                    key={index}
                    className={`journey-step ${index <= dynamicStep ? 'completed' : ''}`}
                    style={{
                      backgroundColor: index <= dynamicStep ? step.stepColor : '#e2e8f0',
                    }}
                  >
                    Step {index + 1}
                  </div>
                ))}
              </div>
              <div className="step-content">
                <div className="step-explanation">
                  {dynamicSteps[dynamicStep].explanation}
                </div>
                <div className="step-formula">
                  <BlockMath math={dynamicSteps[dynamicStep].highlight} />
                </div>
                <div className="step-list">
                  <h4>Steps so far:</h4>
                  <ul>
                    {dynamicSteps.slice(0, dynamicStep + 1).map((step, index) => (
                      <li key={index} style={{ color: step.stepColor }}>
                        {step.variable.name} = {step.variable.value}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="step-navigation">
                  <Button onClick={prevDynamicStep} disabled={dynamicStep === 0}>
                    Previous
                  </Button>
                  <span>Step {dynamicStep + 1} of {dynamicSteps.length}</span>
                  <Button onClick={nextDynamicStep} disabled={dynamicStep === dynamicSteps.length - 1}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Tab.Pane>

          {/* Quiz Tab */}
          <Tab.Pane eventKey="quiz">
            <div className="quiz-section">
              <h3>Quiz</h3>
              <p className="quiz-progress">Question {quizStep + 1} of {quizQuestions.length}</p>
              <p>{quizQuestions[quizStep].question}</p>
              <div className="quiz-input">
                <input
                  type="number"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  placeholder="Your Answer"
                />
                <button onClick={checkAnswer} className="check-button">
                  Check Answer
                </button>
              </div>
              {feedback !== null && (
                <div className={`feedback ${feedback ? 'correct' : 'incorrect'}`}>
                  {feedback ? <Check size={18} /> : <X size={18} />}
                  {feedback ? 'Correct!' : 'Incorrect.'}
                </div>
              )}
              {showExplanation && (
                <div className="explanation">
                  <Info size={18} />
                  <p dangerouslySetInnerHTML={{ __html: quizQuestions[quizStep].explanation }} />
                </div>
              )}
              <div className="quiz-navigation">
                <Button onClick={prevQuizStep} disabled={quizStep === 0}>
                  Previous Question
                </Button>
                <Button onClick={nextQuizStep} disabled={quizStep === quizQuestions.length - 1}>
                  Next Question
                </Button>
                <Button onClick={resetQuiz} className="retake-quiz">Retake Quiz</Button>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* Best Practices */}
      <div className="best-practices">
        <h2>Best Practices</h2>
        <div className="best-practices-grid">
          <div className="good-practices">
            <h3>Good for:</h3>
            <ul>
              <li><span className="icon">📊</span>Datasets without significant outliers</li>
              <li><span className="icon">🔔</span>Normally distributed data</li>
              <li><span className="icon">🔢</span>Continuous numerical data</li>
            </ul>
          </div>
          <div className="bad-practices">
            <h3>Not ideal for:</h3>
            <ul>
              <li><span className="icon">⚠️</span>Datasets with extreme outliers</li>
              <li><span className="icon">↪️</span>Skewed distributions</li>
              <li><span className="icon">🏷️</span>Ordinal or categorical data</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Real-World Applications */}
      <div className="real-world-application">
        <h2>Real-World Examples</h2>
        <div className="application-grid">
          {[
            { icon: '🌡️', text: 'Average daily temperatures', why: 'To track climate trends and make weather forecasts' },
            { icon: '📊', text: 'Stock market performance', why: 'To assess overall market trends and make investment decisions' },
            { icon: '🏃', text: 'Athletic performance metrics', why: 'To measure progress and set training goals' },
            { icon: '💰', text: 'Income and economic indicators', why: 'To gauge economic health and make policy decisions' },
            { icon: '🎓', text: 'Educational test scores', why: 'To evaluate student performance and educational programs' },
            { icon: '⏱️', text: 'Manufacturing process times', why: 'To optimize production efficiency and identify bottlenecks' }
          ].map((app, index) => (
            <div key={index} className="application-item">
              <span className="application-icon">{app.icon}</span>
              <p>{app.text}</p>
              <p className="application-why">Why: {app.why}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Formula Proof */}
      <FormulaProof />
    </div>
  );
};

export default Mean;
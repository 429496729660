import React, { useState, useMemo, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { Tab, Nav, Button } from 'react-bootstrap';
import { Info, Check, X } from 'lucide-react';
import './Median.css';

const Median = () => {
  // Initialize state for data values (using a real-world example: house prices in thousands of dollars)
  const [data, setData] = useState([
    { name: 'House 1', value: 200 },
    { name: 'House 2', value: 250 },
    { name: 'House 3', value: 180 },
    { name: 'House 4', value: 300 },
    { name: 'House 5', value: 220 },
  ]);

  // State for dynamic proof steps
  const [dynamicStep, setDynamicStep] = useState(0);

  // State for quiz
  const [quizStep, setQuizStep] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);

  // Calculations
  const sortedData = [...data].sort((a, b) => a.value - b.value);
  const n = data.length;
  const medianIndex = Math.floor(n / 2);
  const median = n % 2 === 0
    ? (sortedData[medianIndex - 1].value + sortedData[medianIndex].value) / 2
    : sortedData[medianIndex].value;

  // Color legend for variables
  const colorLegend = {
    black: '#000000',
    x: '#4CAF50', // Green
    n: '#2196F3', // Blue
    median: '#9C27B0', // Purple
  };

  // Dynamic proof steps
  const dynamicSteps = [
    {
      explanation: 'Define and sort the dataset: List all the house prices in our sample and arrange them in ascending order.',
      formula: `x_{sorted} = [${sortedData.map((item) => item.value).join(', ')}]`,
      highlight: `\\color{${colorLegend.x}}{x_{sorted} = [${sortedData.map((item) => item.value).join(', ')}]}`,
      variable: { name: 'x_sorted', value: `[${sortedData.map((item) => item.value).join(', ')}]`, color: colorLegend.x },
      stepColor: colorLegend.x,
    },
    {
      explanation: 'Count the number of values and determine if it\'s odd or even:',
      formula: `n = ${n} \\text{ (${n % 2 === 0 ? 'even' : 'odd'})}`,
      highlight: `\\color{${colorLegend.n}}{n = ${n}} \\text{ (${n % 2 === 0 ? 'even' : 'odd'})}`,
      variable: { name: 'n', value: n, color: colorLegend.n },
      stepColor: colorLegend.n,
    },
    {
      explanation: `Apply the median formula based on whether n is ${n % 2 === 0 ? 'even' : 'odd'}:`,
      formula: `\\text{Median} = \\begin{cases} x_{\\frac{n+1}{2}} & \\text{if n is odd} \\\\ \\frac{1}{2}(x_{\\frac{n}{2}} \\color{black}{+} x_{\\frac{n}{2}+1}) & \\text{if n is even} \\end{cases}`,
      highlight: `\\color{${colorLegend.median}}{\\text{Median}} \\color{${colorLegend.black}}{=} \\begin{cases} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}\\color{black}{+}1}{\\color{black}{2}}}} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is odd}} \\\\ \\frac{1}{2}(\\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}}} \\color{${colorLegend.black}}{+} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}\\color{${colorLegend.black}}{+1}}}\\color{${colorLegend.black}}{)} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is even}} \\end{cases}`,
      variable: { name: 'odd or even', value: `${n % 2 === 0 ? 'even' : 'odd'}`, color: colorLegend.median },
      stepColor: colorLegend.black,
    },
    {
      explanation: `Compute the median (${n % 2 === 0 ? 'average of two middle values' : 'middle value'}):`,
      formula: n % 2 === 0
        ? `\\text{Median} = \\frac{1}{2}(x_{\\frac{n}{2}} + x_{\\frac{n}{2}+1}) = \\frac{${sortedData[medianIndex - 1].value} + ${sortedData[medianIndex].value}}{2} = ${median}`
        : `\\text{Median} = x_{\\frac{n+1}{2}} = x_{${medianIndex + 1}} = ${median}`,
      highlight: n % 2 === 0
        ? `\\color{${colorLegend.median}}{\\text{Median}} = \\frac{1}{2}(\\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{${n}}}{2}}} + \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{${n}}}{2}+1}}) = \\frac{\\color{${colorLegend.x}}{${sortedData[medianIndex - 1].value}} + \\color{${colorLegend.x}}{${sortedData[medianIndex].value}}}{2} = \\color{${colorLegend.median}}{${median}}`
        : `\\color{${colorLegend.median}}{\\text{Median}} = \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{${n}}+1}{2}}} = \\color{${colorLegend.x}}{x_{${medianIndex + 1}}} = \\color{${colorLegend.median}}{${median}}`,
      variable: { name: 'median', value: median, color: colorLegend.median },
      stepColor: colorLegend.median,
    },
  ];

  // Quiz questions
  const quizQuestions = [
    {
      question: 'What is the median house price?',
      correctAnswer: median,
      explanation: `The median house price is <span style="color:${colorLegend.median}">${median}</span> thousand dollars. This is the ${n % 2 === 0 ? 'average of the two middle values' : 'middle value'} in the sorted list of prices.`,
    },
    {
      question: 'How many houses are priced below the median?',
      correctAnswer: Math.floor(n / 2),
      explanation: `There are <span style="color:${colorLegend.n}">${Math.floor(n / 2)}</span> houses priced below the median. In a dataset, half of the values are always below the median.`,
    },
    {
      question: 'If a new house priced at 275 thousand dollars is added, what will be the new median?',
      correctAnswer: n % 2 === 0 ? sortedData[medianIndex].value : (sortedData[medianIndex].value + sortedData[medianIndex + 1].value) / 2,
      explanation: `The new median would be <span style="color:${colorLegend.median}">${n % 2 === 0 ? sortedData[medianIndex].value : (sortedData[medianIndex].value + sortedData[medianIndex + 1].value) / 2}</span> thousand dollars. Adding this house shifts the middle position in the sorted list.`,
    },
    {
      question: 'What is the difference between the highest and lowest house prices?',
      correctAnswer: sortedData[n - 1].value - sortedData[0].value,
      explanation: `The range is <span style="color:${colorLegend.x}">${sortedData[n - 1].value - sortedData[0].value}</span> thousand dollars. This is the difference between the highest price (${sortedData[n - 1].value}) and the lowest price (${sortedData[0].value}).`,
    },
  ];

  // Function to add a new data point
  const addDataPoint = () => {
    const newValue = Math.floor(Math.random() * (350 - 150) + 150); // Random value between 150 and 350
    const newName = `House ${data.length + 1}`;
    setData([...data, { name: newName, value: newValue }]);
  };

  // Function to remove the last data point
  const removeDataPoint = () => {
    if (data.length > 1) {
      setData(data.slice(0, -1));
    }
  };

  // Function to update a data value
  const updateDataValue = (index, value) => {
    const newData = [...data];
    newData[index].value = parseFloat(value) || 0;
    setData(newData);
  };

  // Handle navigation in dynamic proof
  const nextDynamicStep = () => {
    if (dynamicStep < dynamicSteps.length - 1) {
      setDynamicStep(dynamicStep + 1);
    }
  };

  const prevDynamicStep = () => {
    if (dynamicStep > 0) {
      setDynamicStep(dynamicStep - 1);
    }
  };

  // Handle quiz answer checking
  const checkAnswer = () => {
    const correct = Math.abs(parseFloat(userAnswer) - quizQuestions[quizStep].correctAnswer) < 0.01;
    setFeedback(correct);
    setShowExplanation(true);
  };

  const nextQuizStep = () => {
    if (quizStep < quizQuestions.length - 1) {
      setQuizStep(quizStep + 1);
      setUserAnswer('');
      setFeedback(null);
      setShowExplanation(false);
    }
  };

  const prevQuizStep = () => {
    if (quizStep > 0) {
      setQuizStep(quizStep - 1);
      setUserAnswer('');
      setFeedback(null);
      setShowExplanation(false);
    }
  };

  const resetQuiz = () => {
    setQuizStep(0);
    setUserAnswer('');
    setFeedback(null);
    setShowExplanation(false);
  };

  // Memoize chart component
  const memoizedChart = useMemo(
    () => (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={sortedData}
          margin={{ top: 50, right: 120, left: 0, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill="#FFA500" maxBarSize={50}>
            <LabelList dataKey="value" position="top" />
          </Bar>
          <ReferenceLine
            y={median}
            label={{
              position: 'right',
              value: `Median: ${median}`,
              fill: '#9C27B0',
              fontSize: 14,
              align: 'left',
              offset: 20
            }}
            stroke="#9C27B0"
            strokeWidth={3}
          />
        </BarChart>
      </ResponsiveContainer>
    ),
    [sortedData, median]
  );

  // Memoize formula proof component
  const FormulaProof = React.memo(() => {
    const proofSteps = useMemo(
      () => [
        {
          text: "Start with the definition of the median: The middle value in a sorted dataset.",
          formula: "\\text{Median} = \\begin{cases} x_{\\frac{n+1}{2}} & \\text{if n is odd} \\\\ \\frac{1}{2}(x_{\\frac{n}{2}} + x_{\\frac{n}{2}+1}) & \\text{if n is even} \\end{cases}",
          highlight: `\\color{${colorLegend.median}}{\\text{Median}} \\color{${colorLegend.black}}{=} \\begin{cases} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}\\color{black}{+1}}{\\color{black}{2}}}} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is odd}} \\\\ \\frac{1}{2}(\\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}}} \\color{${colorLegend.black}}{+} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}\\color{${colorLegend.black}}{+1}}}\\color{${colorLegend.black}}{)} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is even}} \\end{cases}`
        },
        {
          text: "For an odd number of values, select the middle value:",
          formula: "\\text{Median} = x_{\\frac{n+1}{2}}",
          highlight: `\\color{${colorLegend.median}}{\\text{Median}} = \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}\\color{black}{+1}}{\\color{black}{2}}}}`
        },
        {
          text: "For an even number of values, take the average of the two middle values:",
          formula: "\\text{Median} = \\frac{1}{2}(x_{\\frac{n}{2}} + x_{\\frac{n}{2}+1})",
          highlight: `\\color{${colorLegend.median}}{\\text{Median}} = \\frac{1}{2}(\\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}}} \\color{${colorLegend.black}}{+} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}\\color{${colorLegend.black}}{+1}}})`
        },
        {
          text: "This formula ensures that the median always represents the central value, regardless of the number of data points.",
          formula: "\\text{Median} = \\text{Central Value}",
          highlight: `\\color{${colorLegend.median}}{\\text{Median}} = \\color{${colorLegend.x}}{\\text{Central Value}}`
        }
      ],
      []
    );

    return (
      <div className="formula-proof">
        <h2 className="section-title">Mathematical Formula and Proof</h2>
        <div className="formula-proof__main-formula">
          <BlockMath
            math={`\\color{${colorLegend.median}}{\\text{Median}} \\color{${colorLegend.black}}{=} \\begin{cases} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}\\color{black}{+1}}{\\color{black}{2}}}} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is odd}} \\\\ \\frac{1}{2}(\\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}}} \\color{${colorLegend.black}}{+} \\color{${colorLegend.x}}{x_{\\frac{\\color{${colorLegend.n}}{n}}{\\color{black}{2}}\\color{${colorLegend.black}}{+1}}}\\color{${colorLegend.black}}{)} & \\text{\\color{black}{if }} \\color{${colorLegend.n}}{n} \\text{\\color{black}{ is even}} \\end{cases}`}
          />
        </div>
        <div className="formula-proof__legend">
          <p>
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.median}}{\\text{Median}}`} /> is the central value
            </span>
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.n}}{n}`} /> is the number of values
            </span>
            <span className="formula-proof__legend-item">
              <InlineMath math={`\\color{${colorLegend.x}}{x_i}`} /> represents the i-th value in the sorted dataset
            </span>            
          </p>
          <br></br>
        </div>
        <div className="formula-proof__proof">
          <h4 className="formula-proof__subtitle">Proof:</h4>
          <ol className="formula-proof__steps">
            {proofSteps.map((step, index) => (
              <li key={index} className="formula-proof__step">
                <div className="formula-proof__step-content">
                  <p>{step.text}</p>
                  <div className="highlighted-formula">
                    <BlockMath math={step.highlight} />
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
        <div className="formula-proof__conclusion">
          <Info size={18} />
          <p>
            This proof demonstrates how the median formula adapts to both odd and even numbers of data points, always providing the central value of the dataset.
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className="median-container">
      <h1 className="function-title">Calculating the Median</h1>

      <div className="introduction">
        <h2>What is the Median?</h2>
        <p>
          The median is a measure of central tendency that represents the middle value in a sorted dataset. It's particularly useful when dealing with skewed distributions or datasets with extreme outliers.
        </p>
        <p>
          In this interactive tool, we'll explore the concept of median using house prices as an example.
        </p>
      </div>

      {/* Visualization */}
      <div className="visualization-container">
        {memoizedChart}
      </div>

      {/* Input Section */}
      <div className="input-section">
        <h3>Input House Prices (in thousands of dollars)</h3>
        <div className="input-container">
          {data.map((item, index) => (
            <div key={index} className="input-item">
              <label>{item.name}</label>
              <input
                type="number"
                value={item.value}
                onChange={(e) => updateDataValue(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="input-buttons">
          <button onClick={addDataPoint}>Add House</button>
          <button onClick={removeDataPoint}>Remove House</button>
        </div>
      </div>

      {/* Tabs for Dynamic Proof and Quiz */}
      <Tab.Container defaultActiveKey="dynamicProof">
        <Nav variant="pills" className="nav-pills">
          <Nav.Item>
            <Nav.Link eventKey="dynamicProof">Dynamic Proof</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="quiz">Quiz ({quizStep + 1}/{quizQuestions.length})</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          {/* Dynamic Proof Tab */}
          <Tab.Pane eventKey="dynamicProof">
            <div className="dynamic-proof">
              <h3>Dynamic Proof</h3>
              <div className="step-journey">
                {dynamicSteps.map((step, index) => (
                  <div
                    key={index}
                    className={`journey-step ${index <= dynamicStep ? 'completed' : ''}`}
                    style={{
                      backgroundColor: index <= dynamicStep ? step.stepColor : '#e2e8f0',
                    }}
                  >
                    Step {index + 1}
                  </div>
                ))}
              </div>
              <div className="step-content">
                <div className="step-explanation">
                  {dynamicSteps[dynamicStep].explanation}
                </div>
                <div className="step-formula">
                  <BlockMath math={dynamicSteps[dynamicStep].highlight} />
                </div>
                <div className="step-list">
                  <h4>Steps so far:</h4>
                  <ul>
                    {dynamicSteps.slice(0, dynamicStep + 1).map((step, index) => (
                      <li key={index} style={{ color: step.stepColor }}>
                        {step.variable.name} = {step.variable.value}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="step-navigation">
                  <Button onClick={prevDynamicStep} disabled={dynamicStep === 0}>
                    Previous
                  </Button>
                  <span>Step {dynamicStep + 1} of {dynamicSteps.length}</span>
                  <Button onClick={nextDynamicStep} disabled={dynamicStep === dynamicSteps.length - 1}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Tab.Pane>

          {/* Quiz Tab */}
          <Tab.Pane eventKey="quiz">
            <div className="quiz-section">
              <h3>Quiz</h3>
              <p>{quizQuestions[quizStep].question}</p>
              <div className="quiz-input">
                <input
                  type="number"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  placeholder="Your Answer"
                />
                <button onClick={checkAnswer} className="check-button">
                  Check Answer
                </button>
              </div>
              {feedback !== null && (
                <div className={`feedback ${feedback ? 'correct' : 'incorrect'}`}>
                  {feedback ? <Check size={18} /> : <X size={18} />}
                  {feedback ? 'Correct!' : 'Incorrect.'}
                </div>
              )}
              {showExplanation && (
                <div className="explanation">
                  <Info size={18} />
                  <p dangerouslySetInnerHTML={{ __html: quizQuestions[quizStep].explanation }} />
                </div>
              )}
              <div className="quiz-navigation">
                <Button onClick={prevQuizStep} disabled={quizStep === 0}>
                  Previous Question
                </Button>
                <Button onClick={nextQuizStep} disabled={quizStep === quizQuestions.length - 1}>
                  Next Question
                </Button>
                <Button onClick={resetQuiz} className="retake-quiz">Retake Quiz</Button>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* Best Practices */}
      <div className="best-practices">
        <h2>Best Practices</h2>
        <div className="best-practices-grid">
          <div className="good-practices">
            <h3>Good for:</h3>
            <ul>
              <li><span className="icon">📊</span>Datasets with extreme outliers</li>
              <li><span className="icon">↪️</span>Skewed distributions</li>
              <li><span className="icon">🔢</span>Ordinal data</li>
            </ul>
          </div>
          <div className="bad-practices">
            <h3>Not ideal for:</h3>
            <ul>
              <li><span className="icon">⚠️</span>Small datasets with even number of values</li>
              <li><span className="icon">🏷️</span>Nominal (categorical) data</li>
              <li><span className="icon">📈</span>When precise central tendency is needed</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Real-World Applications */}
      <div className="real-world-application">
        <h2>Real-World Examples</h2>
        <div className="application-grid">
          {[
            { icon: '🏠', text: 'Median home prices', why: 'To assess the typical housing cost in a region' },
            { icon: '💰', text: 'Median income', why: 'To measure the economic well-being of a population' },
            { icon: '📊', text: 'Test score reporting', why: 'To represent student performance resistant to outliers' },
            { icon: '⏱️', text: 'Response times', why: 'To measure typical system performance in computing' },
            { icon: '📈', text: 'Financial analysis', why: 'To analyze stock returns and other financial metrics' },
            { icon: '🩺', text: 'Medical studies', why: 'To report patient outcomes and drug efficacy' }
          ].map((app, index) => (
            <div key={index} className="application-item">
              <span className="application-icon">{app.icon}</span>
              <p>{app.text}</p>
              <p className="application-why">Why: {app.why}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Formula Proof */}
      <FormulaProof />
    </div>
  );
};

export default Median;
// src/functions/CorrelationCoefficient.js
import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import './CorrelationCoefficient.css'; // Use for colors and styling

const correlationData = [
  { x: 1, y: 1.5 },
  { x: 2, y: 3.1 },
  { x: 3, y: 4.5 },
  { x: 4, y: 6.0 },
  { x: 5, y: 7.4 },
];

// Calculate Correlation Coefficient Function
const calculateCorrelation = (data) => {
  const n = data.length;
  const sumX = data.reduce((acc, item) => acc + item.x, 0);
  const sumY = data.reduce((acc, item) => acc + item.y, 0);
  const sumXY = data.reduce((acc, item) => acc + item.x * item.y, 0);
  const sumX2 = data.reduce((acc, item) => acc + item.x ** 2, 0);
  const sumY2 = data.reduce((acc, item) => acc + item.y ** 2, 0);

  const numerator = n * sumXY - sumX * sumY;
  const denominator = Math.sqrt(
    (n * sumX2 - sumX ** 2) * (n * sumY2 - sumY ** 2)
  );

  const correlation = numerator / denominator;
  return correlation.toFixed(2);
};

// Visualization Component with Interactive Insights
const CorrelationVisualization = () => {
  const correlation = calculateCorrelation(correlationData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Correlation Coefficient Calculation</h3>
      <ScatterChart
        width={500}
        height={300}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        style={{ margin: '0 auto' }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="x"
          name="Variable X"
          label={{ value: 'X', position: 'insideBottomRight', offset: -5 }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Variable Y"
          label={{ value: 'Y', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name="Data Points" data={correlationData} fill="#2ecc71" />
        <Line
          type="linear"
          dataKey="y"
          data={correlationData}
          stroke="#3498db"
          dot={false}
        />
      </ScatterChart>
      <p className="visualization-description">
        The correlation coefficient is <strong>{correlation}</strong>, indicating a strong positive linear relationship between X and Y.
      </p>
    </div>
  );
};

// Main Correlation Coefficient Function Object
const CorrelationCoefficient = {
  name: 'Correlation Coefficient (ρ_{X,Y})',
  description:
    'The correlation coefficient measures the strength and direction of the linear relationship between two variables, ranging from -1 to 1.',
  goodFor: [
    'Assessing linear relationships.',
    'Comparing relationships across datasets.',
  ],
  notGoodFor: [
    'Non-linear relationships.',
    'Datasets with outliers that can skew the correlation.',
  ],
  visualization: CorrelationVisualization,
  input:
    'Two sets of numerical values ($X = x_1, x_2, \\dots, x_n; Y = y_1, y_2, \\dots, y_n$)',
  output: 'A number between -1 and 1 representing the correlation.',
  formula:
    '\\rho_{X,Y} = \\frac{\\text{Cov}(X,Y)}{\\sigma_X \\sigma_Y} = \\frac{\\sum_{i=1}^{n} (x_i - \\mu_X)(y_i - \\mu_Y)}{\\sqrt{\\sum_{i=1}^{n} (x_i - \\mu_X)^2 \\sum_{i=1}^{n} (y_i - \\mu_Y)^2}}',
};

export default CorrelationCoefficient;

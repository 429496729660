// src/functions/Mode.js
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import './Mode.css'; // Use for colors and styling

const modeData = [
  { name: 'Value 1', value: 20 },
  { name: 'Value 2', value: 15 },
  { name: 'Value 3', value: 20 },
  { name: 'Value 4', value: 25 },
  { name: 'Value 5', value: 20 },
];

// Calculate Mode Function
const calculateMode = (data) => {
  const frequency = {};
  data.forEach((item) => {
    frequency[item.value] = (frequency[item.value] || 0) + 1;
  });
  const maxFreq = Math.max(...Object.values(frequency));
  const modes = Object.keys(frequency)
    .filter((key) => frequency[key] === maxFreq)
    .map(Number);
  return modes;
};

// Visualization Component with Interactive Insights
const ModeVisualization = () => {
  const modes = calculateMode(modeData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Mode Calculation</h3>
      <BarChart
        width={500}
        height={300}
        data={modeData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        style={{ margin: '0 auto' }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip formatter={(value) => `Value: ${value}`} />
        <Bar dataKey="value" fill="#f1c40f">
          <LabelList dataKey="value" position="top" style={{ fill: '#000' }} />
        </Bar>
      </BarChart>
      <p className="visualization-description">
        The <strong>mode</strong> of this dataset is{' '}
        <strong>{modes.join(', ')}</strong>, which is the most frequently occurring value(s).
      </p>
    </div>
  );
};

// Main Mode Function Object
const Mode = {
  name: 'Mode',
  description:
    'The mode is the value that appears most frequently in a data set. It is possible to have more than one mode if multiple values occur with the same maximum frequency.',
  goodFor: [
    'Categorical data analysis.',
    'Datasets with repeated values.',
  ],
  notGoodFor: [
    'Datasets where all values are unique.',
    'Not sensitive to changes in other data points.',
  ],
  visualization: ModeVisualization,
  input: 'A set of numerical or categorical values ($x_1, x_2, \\dots, x_n$)',
  output: 'The most frequently occurring value(s) in the dataset.',
  formula: '\\text{Mode} = \\text{Most Frequent Value of } x_i',
};

export default Mode;

// src/functions/ProbabilityMassFunction.js
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import './ProbabilityMassFunction.css'; // Use for colors and styling

const pmfData = [
  { value: 0, probability: 0.1 },
  { value: 1, probability: 0.2 },
  { value: 2, probability: 0.4 },
  { value: 3, probability: 0.2 },
  { value: 4, probability: 0.1 },
];

// Visualization Component
const PMFVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Probability Mass Function</h3>
      <BarChart
        width={400}
        height={250}
        data={pmfData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid />
        <XAxis dataKey="value" label={{ value: 'k', position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value: 'P(X = k)', angle: -90, position: 'insideLeft' }} />
        <Tooltip formatter={(value) => `P(X=${value})`} />
        <Bar dataKey="probability" fill="#3498db">
          <LabelList dataKey="probability" position="top" formatter={(value) => value.toFixed(2)} />
        </Bar>
      </BarChart>
      <p className="visualization-description">
        This chart represents the <strong>probability mass function (PMF)</strong> of a discrete random variable X. Each bar shows the probability of X taking on the value k.
      </p>
    </div>
  );
};

// Main PMF Function Object
const ProbabilityMassFunction = {
  name: 'Probability Mass Function (p_X(k))',
  description:
    'The probability mass function gives the probability that a discrete random variable is exactly equal to some value.',
  goodFor: [
    'Describing discrete random variables.',
    'Calculating probabilities for specific outcomes.',
  ],
  notGoodFor: [
    'Continuous random variables.',
    'Situations requiring cumulative probabilities.',
  ],
  visualization: PMFVisualization,
  input: 'A discrete random variable X with possible values k and their probabilities.',
  output: 'A function assigning probabilities to each possible value of X.',
  formula: 'p_X(k) = P(X = k)',
};

export default ProbabilityMassFunction;

// src/functions/StandardDeviation.js
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import './StandardDeviation.css'; // Use for colors and styling

const sdData = [
  { name: 'Value 1', value: 9 },
  { name: 'Value 2', value: 14 },
  { name: 'Value 3', value: 17 },
  { name: 'Value 4', value: 23 },
  { name: 'Value 5', value: 26 },
];

// Calculate Standard Deviation Function
const calculateStandardDeviation = (data) => {
  const n = data.length;
  const mean = data.reduce((acc, item) => acc + item.value, 0) / n;
  const variance =
    data.reduce((acc, item) => acc + Math.pow(item.value - mean, 2), 0) / n;
  const stdDev = Math.sqrt(variance);
  return stdDev.toFixed(2);
};

// Visualization Component with Interactive Insights
const StandardDeviationVisualization = () => {
  const stdDev = calculateStandardDeviation(sdData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Standard Deviation Calculation</h3>
      <AreaChart
        width={400}
        height={250}
        data={sdData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#e74c3c"
          fill="#e74c3c"
          opacity={0.3}
        />
        <ReferenceLine
          y={parseFloat(stdDev)}
          label={`Std Dev: ${stdDev}`}
          stroke="#c0392b"
          strokeDasharray="3 3"
        />
      </AreaChart>
      <p className="visualization-description">
        The standard deviation is <strong>{stdDev}</strong>, showing the average distance of the data points from the mean.
      </p>
    </div>
  );
};

// Main Standard Deviation Function Object
const StandardDeviation = {
  name: 'Standard Deviation (S(X) or σ)',
  description:
    'Standard deviation quantifies the amount of variation in a set of data values, indicating how spread out the data is around the mean.',
  goodFor: [
    'Comparing variability between datasets.',
    'Statistical analyses requiring data spread.',
  ],
  notGoodFor: [
    'Datasets with non-normal distributions.',
    'Highly skewed data.',
  ],
  visualization: StandardDeviationVisualization,
  input: 'A set of numerical values ($x_1, x_2, \\dots, x_n$)',
  output: 'A non-negative number representing data spread in original units.',
  formula: '\\sigma = \\sqrt{\\frac{1}{n} \\sum_{i=1}^{n} (x_i - \\mu)^2}',
};

export default StandardDeviation;

// src/functions/Covariance.js
import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import './Covariance.css'; // Use for colors and styling

const covarianceData = [
  { x: 2, y: 3 },
  { x: 4, y: 5 },
  { x: 6, y: 7 },
  { x: 8, y: 9 },
  { x: 10, y: 11 },
];

// Calculate Covariance Function
const calculateCovariance = (data) => {
  const n = data.length;
  const meanX = data.reduce((acc, item) => acc + item.x, 0) / n;
  const meanY = data.reduce((acc, item) => acc + item.y, 0) / n;

  const covariance =
    data.reduce((acc, item) => acc + (item.x - meanX) * (item.y - meanY), 0) /
    n;

  return covariance.toFixed(2);
};

// Visualization Component with Interactive Insights
const CovarianceVisualization = () => {
  const covariance = calculateCovariance(covarianceData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Covariance Calculation</h3>
      <ScatterChart
        width={500}
        height={300}
        data={covarianceData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        style={{ margin: '0 auto' }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="x"
          name="Variable X"
          label={{ value: 'X', position: 'insideBottomRight', offset: -5 }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Variable Y"
          label={{ value: 'Y', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter name="Data Points" data={covarianceData} fill="#e74c3c" />
        <Line
          type="linear"
          dataKey="y"
          data={covarianceData}
          stroke="#3498db"
          dot={false}
        />
        <Legend />
      </ScatterChart>
      <p className="visualization-description">
        The covariance between X and Y is <strong>{covariance}</strong>. A positive covariance indicates that X and Y tend to increase together.
      </p>
    </div>
  );
};

// Main Covariance Function Object
const Covariance = {
  name: 'Covariance (Cov(X,Y))',
  description:
    'Covariance measures the joint variability of two random variables. It indicates the direction of the linear relationship between variables.',
  goodFor: [
    'Identifying the relationship direction between variables.',
    'Understanding how two variables change together.',
  ],
  notGoodFor: [
    'Comparing the strength of relationships (use correlation instead).',
    'Interpreting without considering the units of measurement.',
  ],
  visualization: CovarianceVisualization,
  input:
    'Two sets of numerical values ($X = x_1, x_2, \\dots, x_n; Y = y_1, y_2, \\dots, y_n$)',
  output: 'A number representing the joint variability of X and Y.',
  formula:
    '\\text{Cov}(X,Y) = \\frac{1}{n} \\sum_{i=1}^{n} (x_i - \\mu_X)(y_i - \\mu_Y)',
};

export default Covariance;

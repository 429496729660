// src/functions/BayesTheorem.js
import React from 'react';
import './BayesTheorem.css'; // Use for colors and styling

const BayesTheoremVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Bayes' Theorem</h3>
      <p className="visualization-description">
        Bayes' Theorem relates the conditional and marginal probabilities of events:
        <br />
        <strong>P(B|A) = [P(A|B) P(B)] / P(A)</strong>
      </p>
    </div>
  );
};

// Main Bayes' Theorem Function Object
const BayesTheorem = {
  name: "Bayes' Theorem",
  description:
    "Bayes' Theorem describes the probability of an event based on prior knowledge of conditions that might be related to the event.",
  goodFor: [
    'Updating probabilities with new evidence.',
    'Decision making under uncertainty.',
  ],
  notGoodFor: [
    'Independent events (since P(B|A) = P(B)).',
    'Non-probabilistic reasoning.',
  ],
  visualization: BayesTheoremVisualization,
  input: 'Probabilities P(A|B), P(B), and P(A).',
  output: 'The updated probability P(B|A).',
  formula: 'P(B|A) = \\frac{P(A|B) P(B)}{P(A)}',
};

export default BayesTheorem;

// src/functions/ConditionalProbability.js
import React from 'react';
import './ConditionalProbability.css'; // Use for colors and styling

const ConditionalProbabilityVisualization = () => {
  return (
    <div className="visualization-container">
      <div className="visualization-grid">
        {/* Description on the left */}
        <div className="visualization-description">
          <h3 className="visualization-title">Conditional Probability</h3>
          <p>
            Conditional probability <strong>P(B|A)</strong> represents the probability of event B occurring given that event A has occurred.
          </p>
        </div>

        {/* Venn Diagram on the right */}
        <div className="venn-diagram">
          <div className="circle A">
            <span className="circle-label">A</span>
          </div>
          <div className="circle B">
            <span className="circle-label">B</span>
          </div>
          <div className="overlap-label">A ∩ B</div>
        </div>
      </div>
    </div>
  );
};

// Main Conditional Probability Function Object
const ConditionalProbability = {
  name: 'Conditional Probability (P(B|A))',
  description:
    'Conditional probability is the probability of an event occurring given that another event has already occurred. It helps in updating probabilities based on new information.',
  goodFor: [
    'Dependent events.',
    'Bayesian analysis and inference.',
  ],
  notGoodFor: [
    'Independent events (since P(B|A) = P(B)).',
    'Events with unknown dependencies.',
  ],
  visualization: ConditionalProbabilityVisualization,
  input: 'Events A and B with known probabilities.',
  output: 'The probability of B occurring given A has occurred.',
  formula: 'P(B|A) = \\frac{P(A \\cap B)}{P(A)}',
};

export default ConditionalProbability;

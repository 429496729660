// src/functions/CumulativeDistributionFunction.js
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import './CumulativeDistributionFunction.css'; // Use for colors and styling

const cdfData = [];
let cumulativeProbability = 0;
for (let x = 0; x <= 10; x += 1) {
  cumulativeProbability += 0.1;
  cdfData.push({ x: x, F: cumulativeProbability.toFixed(2) });
}

// Visualization Component
const CDFVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Cumulative Distribution Function</h3>
      <LineChart
        width={400}
        height={250}
        data={cdfData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid />
        <XAxis dataKey="x" label={{ value: 'x', position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value: 'F_X(x)', angle: -90, position: 'insideLeft' }} domain={[0, 1]} />
        <Tooltip formatter={(value) => `F_X(x) = ${value}`} />
        <Line type="stepAfter" dataKey="F" stroke="#9b59b6" strokeWidth={2} />
      </LineChart>
      <p className="visualization-description">
        The <strong>cumulative distribution function (CDF)</strong> represents the probability that a random variable X will take a value less than or equal to x.
      </p>
    </div>
  );
};

// Main CDF Function Object
const CumulativeDistributionFunction = {
  name: 'Cumulative Distribution Function (F_X(x))',
  description:
    'The cumulative distribution function gives the probability that a random variable is less than or equal to a certain value.',
  goodFor: [
    'Calculating probabilities up to a certain point.',
    'Working with both discrete and continuous variables.',
  ],
  notGoodFor: [
    'Finding probabilities over intervals without subtraction.',
    'Describing probability densities.',
  ],
  visualization: CDFVisualization,
  input: 'A random variable X with its cumulative probabilities.',
  output: 'A function mapping x to P(X ≤ x).',
  formula: 'F_X(x) = P(X ≤ x)',
};

export default CumulativeDistributionFunction;

// src/functions/Variance.js
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
} from 'recharts';
import './Variance.css'; // Use for colors and styling

const varianceData = [
  { name: 'Value 1', value: 8 },
  { name: 'Value 2', value: 12 },
  { name: 'Value 3', value: 15 },
  { name: 'Value 4', value: 20 },
  { name: 'Value 5', value: 22 },
];

// Calculate Variance Function
const calculateVariance = (data) => {
  const n = data.length;
  const mean = data.reduce((acc, item) => acc + item.value, 0) / n;
  const variance =
    data.reduce((acc, item) => acc + Math.pow(item.value - mean, 2), 0) / n;
  return variance.toFixed(2);
};

// Visualization Component with Interactive Insights
const VarianceVisualization = () => {
  const variance = calculateVariance(varianceData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Variance Calculation</h3>
      <LineChart
        width={400}
        height={250}
        data={varianceData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#9b59b6"
          strokeWidth={2}
        />
      </LineChart>
      <p className="visualization-description">
        The variance is <strong>{variance}</strong>, indicating how much the data points deviate from the mean.
      </p>
    </div>
  );
};

// Main Variance Function Object
const Variance = {
  name: 'Variance (V(X) or σ²)',
  description:
    'Variance measures the average squared deviation of each number from the mean, representing data dispersion.',
  goodFor: [
    'Assessing data variability.',
    'Statistical modeling.',
  ],
  notGoodFor: [
    'Interpreting in original units (since it’s squared).',
    'Datasets with outliers.',
  ],
  visualization: VarianceVisualization,
  input: 'A set of numerical values ($x_1, x_2, \\dots, x_n$)',
  output: 'A non-negative number representing data dispersion.',
  formula: '\\sigma^2 = \\frac{1}{n} \\sum_{i=1}^{n} (x_i - \\mu)^2',
};

export default Variance;

// src/functions/ExpectationOfFunction.js
import React from 'react';
import './ExpectationOfFunction.css'; // Use for colors and styling

const expectationData = [
  { x: 1, p: 0.2 },
  { x: 2, p: 0.3 },
  { x: 3, p: 0.5 },
];

// Calculate E[g(X)] where g(X) = X^2
const calculateExpectation = (data, g) => {
  return data.reduce((acc, item) => acc + g(item.x) * item.p, 0).toFixed(2);
};

const ExpectationVisualization = () => {
  const expectation = calculateExpectation(expectationData, (x) => x ** 2);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Expectation of a Function of X</h3>
      <p className="visualization-description">
        Given the function g(X) = X², the expected value E[g(X)] is <strong>{expectation}</strong>.
      </p>
    </div>
  );
};

// Main Expectation of a Function Object
const ExpectationOfFunction = {
  name: 'Expectation of a Function of Random Variables (E[g(X)])',
  description:
    'The expected value of a function of a random variable is the weighted average of the function’s values, weighted by the probabilities.',
  goodFor: [
    'Finding expected outcomes of transformations.',
    'Calculating variances and moments.',
  ],
  notGoodFor: [
    'Non-measurable functions.',
    'Situations where the expected value doesn\'t exist.',
  ],
  visualization: ExpectationVisualization,
  input: 'Random variable X with its probability distribution, and a function g(X).',
  output: 'A number representing E[g(X)].',
  formula: 'E[g(X)] = ∑ g(x_i) P(X = x_i)',
};

export default ExpectationOfFunction;

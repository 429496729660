// src/functions/ProbabilityDensityFunction.js
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import './ProbabilityDensityFunction.css'; // Use for colors and styling

// Generate data for a normal distribution
const pdfData = [];
for (let x = -3; x <= 3; x += 0.1) {
  const y = (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * x ** 2);
  pdfData.push({ x: x.toFixed(1), y: y.toFixed(4) });
}

// Visualization Component
const PDFVisualization = () => {
  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Probability Density Function (Normal Distribution)</h3>
      <AreaChart
        width={500}
        height={300}
        data={pdfData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        style={{ margin: '0 auto' }}
      >
        <defs>
          <linearGradient id="colorPdf" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          label={{ value: 'x', position: 'insideBottom', offset: -5 }}
          tick={{ fontSize: 12 }}
        />
        <YAxis
          label={{ value: 'f_X(x)', angle: -90, position: 'insideLeft' }}
          tick={{ fontSize: 12 }}
        />
        <Tooltip formatter={(value) => `f_X(x) = ${value}`} />
        <Area
          type="monotone"
          dataKey="y"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorPdf)"
        />
      </AreaChart>
      <p className="visualization-description">
        This graph shows the <strong>probability density function (PDF)</strong> of a standard normal distribution. The area under the curve between two points represents the probability that X falls within that interval.
      </p>
    </div>
  );
};

// Main PDF Function Object
const ProbabilityDensityFunction = {
  name: 'Probability Density Function (f_X(x))',
  description:
    'The probability density function describes the likelihood of a continuous random variable taking on a range of values. The area under the curve represents probability.',
  goodFor: [
    'Describing continuous random variables.',
    'Calculating probabilities over intervals.',
  ],
  notGoodFor: [
    'Discrete random variables.',
    'Finding exact probabilities at specific points (since P(X = x) = 0 for continuous variables).',
  ],
  visualization: PDFVisualization,
  input: 'A continuous random variable X with its density function f_X(x).',
  output: 'A function showing the density of probabilities across values of X.',
  formula: 'P(a \\leq X \\leq b) = \\int_{a}^{b} f_X(x) \\, dx',
};

export default ProbabilityDensityFunction;

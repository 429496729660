// src/functions/Range.js
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import './Range.css'; // Use for colors and styling

const rangeData = [
  { name: 'Value 1', value: 12 },
  { name: 'Value 2', value: 15 },
  { name: 'Value 3', value: 22 },
  { name: 'Value 4', value: 27 },
  { name: 'Value 5', value: 35 },
];

// Calculate Range Function
const calculateRange = (data) => {
  const values = data.map((item) => item.value);
  const min = Math.min(...values);
  const max = Math.max(...values);
  return { min, max, range: max - min };
};

// Visualization Component with Interactive Insights
const RangeVisualization = () => {
  const { min, max, range } = calculateRange(rangeData);

  return (
    <div className="visualization-container">
      <h3 className="visualization-title">Range Calculation</h3>
      <BarChart
        width={400}
        height={250}
        data={rangeData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#2ecc71">
          <LabelList dataKey="value" position="top" />
        </Bar>
      </BarChart>
      <p className="visualization-description">
        The <strong>range</strong> is calculated by subtracting the minimum value ({min}) from the maximum value ({max}), resulting in a range of {range}.
      </p>
    </div>
  );
};

// Main Range Function Object
const Range = {
  name: 'Range',
  description:
    'The range is the simplest measure of dispersion. It represents the difference between the highest and lowest values in a dataset.',
  goodFor: [
    'Quickly understanding the spread of data.',
    'Identifying outliers.',
  ],
  notGoodFor: [
    'Datasets with extreme outliers.',
    'Providing information about data distribution.',
  ],
  visualization: RangeVisualization,
  input: 'A set of numerical values ($x_1, x_2, \\dots, x_n$)',
  output: 'The difference between the maximum and minimum values.',
  formula: '\\text{Range} = \\text{Max}(x_i) - \\text{Min}(x_i)',
};

export default Range;
